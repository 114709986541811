import { EventAggregator }             from 'aurelia-event-aggregator';
import { inject }                      from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { BooleanStatus }               from 'modules/administration/models/boolean-status';
import { HighwaysRepository }          from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository } from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { LotInterventionsRepository }  from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { EntitiesRepository }          from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }             from 'modules/management/concessions/teams/services/repository';
import { ConcessionsRepository }       from 'modules/management/specifications/concessions/services/repository';
import { MAPApplicationsRepository }   from 'modules/map/management/applications/services/repository';
import { MAPCategoriesRepository }     from 'modules/map/management/categories/services/repository';
import { ManufacturersRepository }     from 'modules/map/management/manufacturers/services/repository';
import { SuppliersRepository }         from 'modules/map/management/suppliers/services/repository';
import { MAPFilesRepository }          from 'modules/map/mat-app-proposals/services/files-repository';
import { BaseFilterFormSchema }        from 'resources/classes/base-filter-form-schema';
import { SessionStorage }              from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, EntitiesRepository, TeamsRepository, MAPCategoriesRepository, MAPApplicationsRepository, SuppliersRepository, ManufacturersRepository, MAPFilesRepository, ConcessionsRepository, HighwaysRepository, InterventionTypesRepository, LotInterventionsRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param entitiesRepository
     * @param teamsRepository
     * @param categoriesRepository
     * @param applicationsRepository
     * @param suppliersRepository
     * @param manufacturersRepository
     * @param mapFilesRepository
     * @param concessionsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     * @param lotInterventionsRepository
     */
    constructor(storage, eventAggregator, i18n, entitiesRepository, teamsRepository, categoriesRepository, applicationsRepository, suppliersRepository, manufacturersRepository, mapFilesRepository, concessionsRepository, highwaysRepository, interventionTypesRepository, lotInterventionsRepository) {
        super(storage, eventAggregator, i18n);
        this.entitiesRepository          = entitiesRepository;
        this.teamsRepository             = teamsRepository;
        this.categoriesRepository        = categoriesRepository;
        this.applicationsRepository      = applicationsRepository;
        this.suppliersRepository         = suppliersRepository;
        this.manufacturersRepository     = manufacturersRepository;
        this.mapFilesRepository          = mapFilesRepository;
        this.concessionsRepository       = concessionsRepository;
        this.highwaysRepository          = highwaysRepository;
        this.interventionTypesRepository = interventionTypesRepository;
        this.lotInterventionsRepository  = lotInterventionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel, selectedLotInformation) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                async (newValue, oldValue) => {
                    if (!newValue) {
                        viewModel.filterModel.highway_id           = null;
                        viewModel.filterModel.intervention_type_id = null;
                    }

                    await this.highwaysRepository.lotGroupedAll({
                        concession_id: newValue,
                    }).then((response) => {
                        this.highway_id.options = this.highway_id.instance.transformOptionsObject(response);
                    });

                    await this.lot_intervention_id.instance.fetchData();
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            required:     false,
            remoteSource: () => this.highwaysRepository.lotGroupedAll({
                concession_id: viewModel.filterModel.concession_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    } else {
                        this.intervention_type_id.options = [{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }];
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.filterModel.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }]);
            },
            observers:    [
                (newValue, oldValue) => {
                    return this.lotInterventionsRepository.lotGroupedAll({
                        concession_id:        viewModel.filterModel.concession_id,
                        highway_id:           viewModel.filterModel.highway_id,
                        intervention_type_id: newValue,
                    }).then((response) => {
                        this.lot_intervention_id.options = this.lot_intervention_id.instance.transformOptionsObject(response);
                    });
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionsRepository.lotGroupedAll({
                concession_id:        viewModel.filterModel.concession_id,
                highway_id:           viewModel.filterModel.highway_id,
                intervention_type_id: viewModel.filterModel.intervention_type_id,
            }),
            observers:    [
                (newValue) => {
                    this.entitiesRepository.activeUserEntities({ lot_intervention_id: newValue }).then((response) => {
                        this.entity_id.options = response;
                    });
                },
            ],
        };

        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            remoteSource: () => this.entitiesRepository.activeUserEntities(),
            observers:    [
                (newValue) => {
                    let target = this.team_id;

                    if (target.instance)
                        target.instance.deselectAll();

                    if (newValue) {
                        target.attributes.disabled = false;

                        target.remoteSource = () => this.entitiesRepository.teams(newValue);
                        target.instance.fetchData();
                    } else {
                        target.attributes.disabled = true;
                    }
                },
            ],
            size:         3,
            required:     false,
        };

        this.team_id = {
            type:         'multiselect-native',
            key:          'team_id',
            label:        'form.field.team',
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            size:         3,
            required:     false,
            attributes:   {
                disabled: !viewModel.filterModel.entity_id,
            },
        };

        this.internal_order = {
            type:     'number',
            key:      'internal_order',
            label:    'form.field.internal-order',
            size:     2,
            required: false,
        };

        this.map_number = {
            type:     'text',
            key:      'map_number',
            label:    'form.field.map-number',
            size:     2,
            required: false,
        };

        this.map_reference = {
            type:     'text',
            key:      'map_reference',
            label:    'form.field.map-reference',
            size:     2,
            required: false,
        };

        this.map_date = {
            type:     'material-ui-date-picker',
            key:      'map_date',
            label:    'form.field.map-date',
            size:     4,
            required: false,
        };

        this.approval_date = {
            type:     'material-ui-date-picker',
            key:      'approval_date',
            label:    'form.field.approval-date',
            size:     4,
            required: false,
        };

        this.category_description = {
            type:         'multiselect-native',
            key:          'category_description',
            label:        'form.field.category-description',
            remoteSource: () => viewModel.repository.descriptions(null),
            size:         6,
            required:     false,
            attributes:   {
                disabled:    true,
                displayText: 'Selecione uma categoria para filtrar',
            },
        };

        this.category_id = {
            type:         'multiselect-native',
            key:          'category_id',
            label:        'form.field.category',
            size:         4,
            required:     false,
            remoteSource: () => this.categoriesRepository.active(),
            observers:    [
                (newValue) => {
                    let field = this.category_description;
                    newValue  = this.category_id.instance.model.value;

                    if (newValue) {
                        field.attributes.disabled = false;

                        field.remoteSource = () => viewModel.repository.descriptions({ 'category_id': newValue });
                        field.instance.fetchData();
                    } else {
                        field.attributes.disabled = true;
                    }
                },
            ],
        };

        this.application_id = {
            type:         'multiselect-native',
            key:          'application_id',
            label:        'form.field.application',
            size:         4,
            required:     false,
            remoteSource: () => this.applicationsRepository.active(),
        };

        this.material_type_description = {
            type:     'textarea',
            key:      'material_type_description',
            label:    'form.field.material-type-description',
            size:     4,
            required: false,
        };

        this.manufacturer_id = {
            type:         'multiselect-native',
            key:          'manufacturer_id',
            label:        'form.field.manufacturer',
            remoteSource: () => this.manufacturersRepository.all(),
            size:         3,
            required:     false,
        };

        this.other_manufacturer = {
            type:         'multiselect-native',
            key:          'other_manufacturer',
            label:        'form.field.other-manufacturer',
            remoteSource: () => viewModel.repository.otherManufacturers(),
            size:         3,
            required:     false,
        };

        this.supplier_id = {
            type:         'multiselect-native',
            key:          'supplier_id',
            label:        'form.field.supplier',
            remoteSource: () => this.suppliersRepository.all(),
            size:         3,
            required:     false,
        };

        this.other_supplier = {
            type:         'multiselect-native',
            key:          'other_supplier',
            label:        'form.field.other-supplier',
            remoteSource: () => viewModel.repository.otherSuppliers(),
            size:         3,
            required:     false,
        };

        this.validation_status = {
            type:     'boolean-options-select',
            key:      'validation_status',
            label:    'form.field.validation-status',
            size:     3,
            required: false,
        };

        this.opinion = {
            type:     'textarea',
            key:      'opinion',
            label:    'form.field.opinion',
            size:     3,
            required: false,
        };

        this.no_supplier_and_manufacturer = {
            type:     'checkbox',
            key:      'no_supplier_and_manufacturer',
            label:    'form.field.no-supplier-and-manufacturer',
            size:     4,
            required: false,
        };

        this.map_date_from = {
            type:     'material-ui-date-picker',
            key:      'map_date_from',
            label:    'form.field.map-date(from)',
            size:     2,
            required: false,
        };

        this.map_date_to = {
            type:     'material-ui-date-picker',
            key:      'map_date_to',
            label:    'form.field.map-date(to)',
            size:     2,
            required: false,
        };

        const schema = [
            [this.concession_id, this.highway_id],
            [this.intervention_type_id, this.lot_intervention_id],
            [this.entity_id, this.team_id, this.internal_order, this.map_number, this.map_reference],
            [this.category_id, this.application_id, this.material_type_description],
            [this.category_description, this.manufacturer_id, this.other_manufacturer],
            [this.supplier_id, this.other_supplier, this.validation_status, this.opinion],
            [this.no_supplier_and_manufacturer, this.map_date_from, this.map_date_to],
        ];

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn',
                style: 'background-color: #0092D5 !important; border-color: #0092D5 !important; color: #ffffff !important;',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', {
                listingId:      viewModel.listingId,
                ignoredFilters: viewModel.ignoredFilters,
            }),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            ...schema,
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
